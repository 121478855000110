<template>
  <div class="collectorUnitDetail_wrap">
    <div class="collectorUnitDetail_box">
      <el-card class="box_card left_box_card">
        <div class="box_card_title_wrap">
          <div class="box_card_title">采集器单元基本信息</div>
          <el-button
            class="Edit_btn"
            type="primary"
            size="small"
            plain
            @click="openEditCollectorUnitDialog"
            >编辑</el-button
          >
        </div>
        <div class="detail_main_wrap">
          <div class="detail_left_main_wrap">
            <!-- <el-image src="../../assets/equipmentType/normalType.png" /> -->
            <img :src="imgUrl" />
            <!-- <img src="../../assets/equipmentType/normalType.png" /> -->
          </div>
          <div class="detail_right_main_wrap">
            <div class="left_text_info_box">
              <div class="detail_info_row">
                <span>采集器单元名称：</span>{{ collectorUnitDetailData.name }}
              </div>
              <div class="detail_info_row">
                <span>采集器单元ID：</span>{{ collectorUnitDetailData.code }}
              </div>

              <div class="detail_info_row">
                <span>{{ collectorUnitDetailData.metrics }}：</span
                >{{ collectorUnitDetailData.metricValue }}
                {{ collectorUnitDetailData.metricValueUnit }}
              </div>
              <div class="detail_info_row">
                <span>采集时间：</span>{{ collectorUnitDetailData.collectTime }}
              </div>
              <div class="detail_info_row">
                <span>采集器单元状态：</span
                >{{ statusOptions[collectorUnitDetailData.status] }}
              </div>
            </div>
            <div class="right_text_info_box">
              <div class="detail_info_row">
                <span>隶属设备名称：</span
                >{{ collectorUnitDetailData.collector.split('/')[1] }}
              </div>
              <div class="detail_info_row">
                <span>采集器名称：</span
                >{{ collectorUnitDetailData.collector.split('/')[0] }}
              </div>
              <div class="detail_info_row">
                <span>接入时间：</span>{{ collectorUnitDetailData.gmtCreate }}
              </div>
              <div class="detail_info_row">
                <span>更新时间：</span>{{ collectorUnitDetailData.gmtUpdate }}
              </div>
            </div>
          </div>
        </div>
      </el-card>
      <el-card class="box_card right_box_card">
        <div class="box_card_title_wrap">
          <div class="box_card_title">告警阈值</div>
        </div>
        <div class="threshold_box_wrap">
          <div class="threshold_title_row threshold_row">
            <div>类别</div>
            <div class="threshold_text">阈值设置</div>
            <div class="threshold_delete_btn">操作</div>
          </div>
          <div
            class="threshold_row"
            v-for="thresholdItem in collectorUnitDetailData.threshold"
            :key="thresholdItem.id"
          >
            <div
              :class="{
                text_color_orange: thresholdItem.level === 4,
                text_color_red: thresholdItem.level === 2
              }"
            >
              {{ statusOptions[thresholdItem.level] }}
            </div>
            <div
              class="threshold_text"
              :class="{
                text_color_orange: thresholdItem.level === 4,
                text_color_red: thresholdItem.level === 2
              }"
            >
              {{ thresholdItem.thresholdStr }}
            </div>
            <el-button
              type="danger"
              size="small"
              class="threshold_delete_btn"
              @click="handleDeleteThreshold(thresholdItem.id)"
              >删除</el-button
            >
          </div>
        </div>
      </el-card>
    </div>
    <div class="collectorUnitList_box">
      <div class="collectorUnitList_wrap">
        <div class="collectorUnit_echarts_wrap">
          <div class="echarts_title">
            <el-radio-group v-model="currentTime">
              <el-radio-button
                v-for="currentTimeItem in currentTimeOptions"
                :label="currentTimeItem"
                :key="currentTimeItem"
              />
              <!-- <el-radio-button label="近一小时" />
              <el-radio-button label="近二十四小时" />
              <el-radio-button label="近七十二小时" />
              <el-radio-button label="近七天" /> -->
            </el-radio-group>
          </div>
          <div
            class="echarts_main"
            ref="chartRef"
            style="width: 100%; height: 500px"
          ></div>
        </div>
        <div class="warningMsgList_wrap">
          <div class="warningMsgList_title">告警记录</div>
          <div class="warningMsgList_main">
            <el-table
              :data="waringMsgListData"
              stripe
              :row-style="{ height: '75px' }"
              style="width: 100%"
              :show-header="false"
            >
              <el-table-column min-width="80%">
                <template #default="{ row }">
                  <div class="scope">
                    <div class="scope_row">
                      <div
                        class="collectorUnit_red_name"
                        :class="{
                          collectorUnit_yellow_name: row.level === 4,
                          collectorUnit_red_name: row.level === 2
                        }"
                      >
                        {{ row.content.waringLevel }}
                      </div>
                      <div class="collectorUnit_name">{{ row.gmtCreate }}</div>
                    </div>
                    <div class="collectorUnit_name">{{ row.content.info }}</div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column align="center" min-width="20%">
                <template #default="{ row }">
                  <div class="btns">
                    <el-button
                      :disabled="row.status"
                      @click="handleAreadyRead(row.id)"
                      >已读</el-button
                    >
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <div class="pagination_row">
              <div class="pagination_row_left">
                共{{ totalCollectorUnitNum }}条
              </div>
              <div class="pagination_row_right">
                <el-pagination
                  background
                  layout="prev, pager, next"
                  :total="totalCollectorUnitNum"
                  :page-size="PAGESIZE"
                  v-model:current-page="searchData.pageNum"
                  @current-change="getRecordListApi"
                  class="pagination_item"
                >
                </el-pagination>
                <div class="pagination_row_input_wrap">
                  跳转到<el-input-number
                    class="pagination_row_input_box"
                    style="height: 30px"
                    v-model="pageInputNum"
                    :step="1"
                    :precision="0"
                    :min="1"
                    :max="maxPageNum"
                    size="small"
                  />页
                </div>
                <el-button
                  style="height: 30px"
                  class="pagination_row_btn"
                  size="small"
                  @click="handleJumpPage"
                  >确定</el-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      v-model="editCollectorUnitDialogVisiable"
      title="设置告警阈值"
      width="50%"
      class="edit_collectorUnit_dialog_box"
    >
      <div class="dialog_main">
        <el-form
          :model="editCollectorUnitDetailData"
          label-width="180px"
          label-position="left"
        >
          <div class="dialog_row">
            <div>采集器单元名称：</div>
            <div class="dialog_row_text">
              {{ editCollectorUnitDetailData.name }}
            </div>
          </div>
          <div class="dialog_row_box">
            <div class="dialog_box_row">
              <div>
                <el-switch
                  v-model="earlyWarningItem.flag"
                  style="--el-switch-on-color: #13ce66"
                />
              </div>
              <div class="dialog_row_text" style="color: #f59a23">
                预警项设置：
              </div>
            </div>
            <div class="warning_row">
              <div class="warning_row_text">最大值:</div>
              <el-input-number
                v-model="earlyWarningItem.maxThreshold"
                :precision="2"
                :step="0.1"
                size="small"
                :disabled="!earlyWarningItem.flag"
              />
            </div>
            <div class="warning_row">
              <div class="warning_row_text">最小值:</div>
              <el-input-number
                v-model="earlyWarningItem.minThreshold"
                :precision="2"
                :step="0.1"
                size="small"
                :disabled="!earlyWarningItem.flag"
              />
            </div>
          </div>
          <div class="dialog_row_box">
            <div class="dialog_row">
              <div>
                <el-switch
                  v-model="warningItem.flag"
                  style="--el-switch-on-color: #13ce66"
                />
              </div>
              <div class="dialog_row_text" style="color: #d84242">
                报警项设置:
              </div>
            </div>
            <div class="warning_row">
              <div class="warning_row_text">最大值:</div>
              <el-input-number
                v-model="warningItem.maxThreshold"
                :precision="2"
                :step="0.1"
                size="small"
                :disabled="!warningItem.flag"
              />
            </div>
            <div class="warning_row">
              <div class="warning_row_text">最小值:</div>
              <el-input-number
                v-model="warningItem.minThreshold"
                :precision="2"
                :step="0.1"
                size="small"
                :disabled="!warningItem.flag"
              />
            </div>
          </div>
        </el-form>
      </div>
      <template #footer>
        <span class="dialog_footer">
          <!-- <el-button @click="handleClose">取消</el-button> -->
          <el-button @click="handleCancleEditCollectorUnit" size="default"
            >取消</el-button
          >
          <el-button
            type="primary"
            @click="handleEditCollectorUnit"
            size="default"
            >提交</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { ElMessage } from 'element-plus';
import router from '@/router/index.js';
import {
  getCollectorUnitDetail,
  getHistoryMetrics,
  putThreshold,
  getRecordList,
  putWaringMsgRead
  // getCollectorDetail,
  // getEquipmentDetail
} from '@/api/equipment.js';
import {
  watch,
  ref,
  reactive,
  onMounted,
  onBeforeUnmount,
  computed
} from 'vue';
import { getItem } from '@/utils/storage';
import * as echarts from 'echarts';
import equipmentTypeImg from '@/assets/equipmentType/equipmentType.png';
import powerTypeImg from '@/assets/equipmentType/powerType.png';
import soilTypeImg from '@/assets/equipmentType/soilType.png';
import waterTypeImg from '@/assets/equipmentType/waterType.png';
import normalTypeImg from '@/assets/equipmentType/normalType.png';

// console.log(echarts);
// 监听;
let initFlag = true;
watch(
  () => router.currentRoute.value.fullPath,
  (newValue, oldValue) => {
    console.log('watch', newValue);
    const id = getItem('UnitId');
    const chipId = getItem('chipId');
    const cityCode = getItem('cityCode');
    const equipmentId = getItem('equipmentId');
    if (newValue == '/equipment/collectorUnitDetail') {
      if (id != null) {
        router.push({
          path: '/equipment/collectorUnitDetail',
          query: { id }
        });
        initFlag = false;
      } else if (chipId != null) {
        router.push({
          path: '/equipment/collectorDetail',
          query: { id: chipId, cityCode }
        });
        ElMessage.error('请从采集器详情中选择采集器单元');
        initFlag = false;
      } else if (equipmentId) {
        router.push({
          path: '/equipment/equipmentDetail',
          params: { id: equipmentId }
        });
        ElMessage.error('请从设备详情中选择采集器详情');
        initFlag = false;
      } else {
        router.push({
          path: '/equipment/equipmentList'
        });
        ElMessage.error('请从设备列表中选择设备详情');
        initFlag = false;
      }
    }
  },
  { immediate: true }
);

// 采集器单元 ID
const unitId = getItem('UnitId');

const collectorUnitDetailData = reactive({
  code: '',
  collectTime: '',
  collectTimeList: {},
  collector: '',
  collectorCode: '',
  collectorId: 0,
  dataField: [
    // {
    //   dataMarker: 'string',
    //   dataMarkerName: 'string'
    // }
  ],
  gmtCreate: '',
  gmtUpdate: '',
  id: 0,
  metricValue: '',
  metricValueUnit: '',
  metrics: '',
  metricsType: 0,
  multiData: 0,
  multiMetricValue: {
    // additionalProp1: '',
    // additionalProp2: '',
    // additionalProp3: ''
  },
  multiThreshold: {
    // additionalProp1: [
    //   {
    //     level: 0,
    //     maxThreshold: 0,
    //     metricType: '',
    //     minThreshold: 0
    //   }
    // ],
    // additionalProp2: [
    //   {
    //     level: 0,
    //     maxThreshold: 0,
    //     metricType: '',
    //     minThreshold: 0
    //   }
    // ],
    // additionalProp3: [
    //   {
    //     level: 0,
    //     maxThreshold: 0,
    //     metricType: '',
    //     minThreshold: 0
    //   }
    // ]
  },
  name: '',
  status: '',
  // 报警阈值
  threshold: [
    // {
    //   level: 0, // 报警级别，4-预警, 2-报警
    //   maxThreshold: 0, // 最大阈值
    //   metricType: '', // 指标类型
    //   minThreshold: 0 // 最小阈值
    // }
    // {
    //     "level": 4,
    //     "metricType": "undefined",
    //     "minThreshold": 0.98,
    //     "maxThreshold": 1.1
    //   },
    //   {
    //     "level": 2,
    //     "metricType": "undefined",
    //     "minThreshold": 0.9,
    //     "maxThreshold": 1.2
    //   }
  ],
  waringMsgList: [
    // {
    //   collectorUnitCode: '',
    //   collectorUnitPath: '',
    //   content: {
    //     additionalProp1: {},
    //     additionalProp2: {},
    //     additionalProp3: {}
    //   },
    //   gmtCreate: '',
    //   id: 0,
    //   level: 0,
    //   status: true
    // }
  ]
});
// 编辑采集器单元弹框是否显示
const editCollectorUnitDialogVisiable = ref(false);
// 编辑 采集器单元详情所需的数据
const editCollectorUnitDetailData = reactive({
  code: '',
  collectTime: '',
  collectTimeList: {},
  collector: '',
  collectorCode: '',
  collectorId: 0,
  dataField: [
    // {
    //   dataMarker: 'string',
    //   dataMarkerName: 'string'
    // }
  ],
  gmtCreate: '',
  gmtUpdate: '',
  id: 0,
  metricValue: '',
  metricValueUnit: '',
  metrics: '',
  metricsType: 0,
  multiData: 0,
  multiMetricValue: {
    // additionalProp1: '',
    // additionalProp2: '',
    // additionalProp3: ''
  },
  multiThreshold: {
    // additionalProp1: [
    //   {
    //     level: 0,
    //     maxThreshold: 0,
    //     metricType: '',
    //     minThreshold: 0
    //   }
    // ],
    // additionalProp2: [
    //   {
    //     level: 0,
    //     maxThreshold: 0,
    //     metricType: '',
    //     minThreshold: 0
    //   }
    // ],
    // additionalProp3: [
    //   {
    //     level: 0,
    //     maxThreshold: 0,
    //     metricType: '',
    //     minThreshold: 0
    //   }
    // ]
  },
  name: '',
  status: '',
  // 报警阈值
  threshold: [
    // {
    //   level: 0, // 报警级别，4-预警, 2-报警
    //   maxThreshold: 0, // 最大阈值
    //   metricType: '', // 指标类型
    //   minThreshold: 0 // 最小阈值
    // }
    // {
    //     "level": 4,
    //     "metricType": "undefined",
    //     "minThreshold": 0.98,
    //     "maxThreshold": 1.1
    //   },
    //   {
    //     "level": 2,
    //     "metricType": "undefined",
    //     "minThreshold": 0.9,
    //     "maxThreshold": 1.2
    //   }
  ],
  waringMsgList: [
    // {
    //   collectorUnitCode: '',
    //   collectorUnitPath: '',
    //   content: {
    //     additionalProp1: {},
    //     additionalProp2: {},
    //     additionalProp3: {}
    //   },
    //   gmtCreate: '',
    //   id: 0,
    //   level: 0,
    //   status: true
    // }
  ]
});
// 预警项
const earlyWarningItem = reactive({
  flag: false,
  minThreshold: 0,
  maxThreshold: 0
});
// 报警项
const warningItem = reactive({
  flag: false,
  minThreshold: 0,
  maxThreshold: 0
});
// 获取采集器单元详情
function getCollectorUnitDetailApi(id, isEdit = false) {
  return getCollectorUnitDetail(id).then((res) => {
    const { code, msg, data } = res;
    if (code === 200) {
      if (!isEdit) {
        console.log(data);
        // 当采集器ID为空的时候,代表是第一次拿采集器单元详情信息,这个时候 去查询一下对应的设备的类型信息
        // 埋个雷,这里返回的 采集器Id为null所以没办法从下往上查询,转用策略2(会有瑕疵的策略):用getItem和setItem的方式存储当前的设备类型
        // 更好的做法是让后端将这个字段补齐,或者直接拿到那个设备对应的类型
        // if (!collectorUnitDetailData.collectorId) { getEquipmentListTypeApi(data?.collectorId); }
        // 更新采集单元详情数据
        for (const key of Object.keys(collectorUnitDetailData)) {
          collectorUnitDetailData[key] = data[key];
        }

        // 设置 历史时间指标选项
        const length = currentTimeOptions.value.length;
        currentTimeOptions.value.splice(
          0,
          length,
          ...Array.from(Object.keys(data.collectTimeList))
        );
        console.log('设置 历史时间指标选项', currentTimeOptions.value);
        currentTime.value = currentTimeOptions.value[0];
        let tempId = 0;
        // debugger;
        // 处理告警阈值
        collectorUnitDetailData.threshold?.forEach((item) => {
          item.id = tempId++;
          item.thresholdStr =
            '小于' + item.minThreshold + ', 大于' + item.maxThreshold;
        });
        // 获取 告警记录
        getRecordListApi();
        // 获取 历史指标数据
        return getHistoryMetricsApi();
      } else {
        // 更新编辑采集单元详情数据
        for (const key of Object.keys(editCollectorUnitDetailData)) {
          editCollectorUnitDetailData[key] = data[key];
        }

        if (editCollectorUnitDetailData.threshold) {
          // 处理 报警阈值
          const warningItemIndex =
            editCollectorUnitDetailData.threshold.findIndex(
              (item) => item.level === 2
            );
          if (warningItemIndex !== -1) {
            warningItem.flag = true;
            warningItem.minThreshold =
              editCollectorUnitDetailData.threshold[
                warningItemIndex
              ].minThreshold;
            warningItem.maxThreshold =
              editCollectorUnitDetailData.threshold[
                warningItemIndex
              ].maxThreshold;
          } else {
            warningItem.flag = false;
            warningItem.minThreshold = 0;
            warningItem.maxThreshold = 0;
          }
          // 处理 预警阈值
          const earlyWarningItemIndex =
            editCollectorUnitDetailData.threshold.findIndex(
              (item) => item.level === 4
            );
          if (earlyWarningItemIndex !== -1) {
            earlyWarningItem.flag = true;
            earlyWarningItem.minThreshold =
              editCollectorUnitDetailData.threshold[
                earlyWarningItemIndex
              ].minThreshold;
            earlyWarningItem.maxThreshold =
              editCollectorUnitDetailData.threshold[
                earlyWarningItemIndex
              ].maxThreshold;
          } else {
            earlyWarningItem.flag = false;
            earlyWarningItem.minThreshold = 0;
            earlyWarningItem.maxThreshold = 0;
          }
          console.log(editCollectorUnitDetailData.threshold);
          console.log(earlyWarningItem);
          console.log(warningItem);
        }

        return null;
      }
    } else {
      ElMessage.error(msg);
    }
  });
}
// 取消编辑采集器单元
function handleCancleEditCollectorUnit() {
  editCollectorUnitDialogVisiable.value = false;
}
// 编辑采集器单元
function handleEditCollectorUnit() {
  console.log('handleEditCollectorUnit');
  const thresholdList = [];
  if (earlyWarningItem.flag) {
    thresholdList.push({
      level: 4,
      maxThreshold: earlyWarningItem.maxThreshold,
      minThreshold: earlyWarningItem.minThreshold
    });
  }
  if (warningItem.flag) {
    thresholdList.push({
      level: 2,
      maxThreshold: warningItem.maxThreshold,
      minThreshold: warningItem.minThreshold
    });
  }
  const data = {
    collectorUnitId: unitId,
    thresholdList
  };
  putThreshold(data).then((res) => {
    const { code, msg } = res;
    if (code === 200) {
      // 刷新一下,因为可能会有预警或者报警
      editCollectorUnitDialogVisiable.value = false;
      getCollectorUnitDetailApi(unitId).then(() => {
        const option = getEchartsOption();
        // debugger;
        // TODO: 这里的话,当 预警和告警都是没有的时候,指引线 和 右上角 会不更新
        // BUGGER
        option && myChart.setOption(option);
      });
    } else {
      ElMessage.error(msg);
    }
  });
}
// 打开编辑
function openEditCollectorUnitDialog() {
  editCollectorUnitDialogVisiable.value = true;
  getCollectorUnitDetailApi(unitId, true);
}

// 删除告警阈值
function handleDeleteThreshold(id) {
  const thresholdList = collectorUnitDetailData.threshold;
  const index = thresholdList.findIndex((item) => item.id === id);
  thresholdList.splice(index, 1);
  const data = {
    collectorUnitId: unitId,
    thresholdList
  };
  putThreshold(data).then((res) => {
    const { code } = res;
    if (code === 200) {
      ElMessage.success('删除成功!');
      if (!thresholdList.length) {
        console.log('销毁重建Echarts图表');
        const chartDom = chartRef.value;
        // 销毁操作
        myChart.dispose();
        myChart = echarts.init(chartDom);
      }
      const option = getEchartsOption();
      option && myChart.setOption(option);
    }
  });
}

// 采集器单元状态数组
const statusOptions = {
  1: '正常',
  2: '报警',
  3: '离线',
  4: '预警'
};

// 历史指标数据时间选项 currentTime
const currentTimeOptions = ref([]);
// 当前历史指标数据时间
const currentTime = ref('');
const metricsData = reactive({
  metrics: '',
  unit: '',
  xaxis: [],
  yaxis: []
});
// 当选择当前时间发生改变,发起请求拿到最新的数据,然后重新渲染 图表
watch(currentTime, () => {
  getHistoryMetricsApi().then(() => {
    if (myChart) {
      const option = getEchartsOption();
      option && myChart.setOption(option);
    }
  });
});

// 获取历史指标数据Api
function getHistoryMetricsApi() {
  const params = {
    collectorUnitId: collectorUnitDetailData.id,
    time: collectorUnitDetailData.collectTimeList[currentTime.value]
  };
  console.log(params);
  return getHistoryMetrics(params).then((res) => {
    const { code, msg, data } = res;
    if (code === 200) {
      for (const key of Object.keys(metricsData)) {
        metricsData[key] = data[key];
      }
    } else {
      ElMessage.error(msg);
    }
  });
}

const toFixedNum = 2;
const chartRef = ref(null);
let myChart;
function getEchartsOption() {
  const option = {
    title: {
      text: metricsData.metrics, // '继电器状态', // 标题
      left: '1%'
    },
    emphasis: {
      focus: 'series',
      blurScope: 'coordinateSystem'
    },
    tooltip: {
      trigger: 'axis',
      valueFormatter: (value) =>
        Number(value).toFixed(toFixedNum) + metricsData.unit // 单位
    },
    grid: {
      left: '5%',
      right: '15%',
      bottom: '10%'
    },
    xAxis: {
      name: '时间',
      data: metricsData.xaxis
    },
    yAxis: {
      name: metricsData.metrics + ' ' + metricsData.unit, // '继电器状态' + '', // 单位
      // TODO: 图的最大值 这个数据没有
      // max: Number(2.0).toFixed(toFixedNum),
      // splitNumber: 0.10,
      // TODO: 图的最小间隔没有 这个数据没有
      // maxInterval: 0.1,
      axisLabel: {
        formatter: function (value, index) {
          return Number(value).toFixed(toFixedNum) + metricsData.unit; // 单位
        }
      }
    },
    toolbox: {
      right: 10,
      feature: {
        dataZoom: {
          yAxisIndex: 'none'
        },
        restore: {},
        saveAsImage: {}
      }
    },
    dataZoom: [
      {
        startValue: metricsData.xaxis[0]
      },
      {
        type: 'inside'
      }
    ],
    // visualMap: {
    //   top: 50,
    //   right: 10,
    //   pieces: visualMapPieces,
    //   // [
    //   //   {
    //   //     gt: 0.0,
    //   //     lte: 0.8,
    //   //     label: `0.00 ${metricsData.unit} - 0.80 ${metricsData.unit}`, // 单位
    //   //     color: '#d84242' // 红色
    //   //   },
    //   //   {
    //   //     gt: 0.8,
    //   //     lte: 0.9,
    //   //     label: `0.80 ${metricsData.unit} - 0.90 ${metricsData.unit}`, // 单位
    //   //     color: '#f59a23' // 黄色
    //   //   },
    //   //   {
    //   //     gt: 0.9,
    //   //     lte: 1.1,
    //   //     label: `0.90 ${metricsData.unit} - 1.10 ${metricsData.unit}`, // 单位
    //   //     color: '#93CE07' // 绿色
    //   //   },
    //   //   {
    //   //     gt: 1.1,
    //   //     lte: 1.2,
    //   //     label: `1.10 ${metricsData.unit} - 1.20 ${metricsData.unit}`, // 单位
    //   //     color: '#f59a23' // 黄色
    //   //   },
    //   //   {
    //   //     gt: 1.2,
    //   //     label: `> 1.20 ${metricsData.unit}`, // 单位
    //   //     color: '#d84242' // 红色
    //   //   }
    //   // ],
    //   outOfRange: {
    //     color: '#999'
    //   }
    // },
    series: {
      name: metricsData.metrics, // '继电器状态', // 悬浮点展示文字
      label: {
        formatter: function (value) {
          return Number(value).toFixed(toFixedNum) + metricsData.unit;
        }
      },
      type: 'line',
      data: metricsData.yaxis,
      markLine: {
        silent: true,
        precision: toFixedNum,
        lineStyle: {
          color: '#333'
        }
        // data: [
        //   {
        //     yAxis: 0.8,
        //     lineStyle: {
        //       color: '#d84242' // 红色
        //     },
        //     label: {
        //       color: '#d84242', // 红色
        //       formatter: function (value, index) {
        //         return (
        //           Number(value.value).toFixed(toFixedNum) + metricsData.unit
        //         ); // 单位
        //       }
        //     }
        //   },
        //   {
        //     yAxis: 0.9,
        //     lineStyle: {
        //       color: '#f59a23'
        //     },
        //     label: {
        //       color: '#f59a23',
        //       formatter: function (value, index) {
        //         return (
        //           Number(value.value).toFixed(toFixedNum) + metricsData.unit
        //         ); // 单位
        //       }
        //     }
        //   },
        //   {
        //     yAxis: 1.1,
        //     lineStyle: {
        //       color: '#f59a23'
        //     },
        //     label: {
        //       color: '#f59a23',
        //       formatter: function (value, index) {
        //         return (
        //           Number(value.value).toFixed(toFixedNum) + metricsData.unit
        //         ); // 单位
        //       }
        //     }
        //   },
        //   {
        //     yAxis: 1.2,

        //     lineStyle: {
        //       color: '#d84242' // 红色
        //     },
        //     label: {
        //       color: '#d84242', // 红色
        //       formatter: function (value, index) {
        //         return (
        //           Number(value.value).toFixed(toFixedNum) + metricsData.unit
        //         ); // 单位
        //       }
        //     }
        //   }
        // ]
      }
    }
  };

  // 处理 报警阈值
  const warningInfo = { flag: false };
  if (collectorUnitDetailData.threshold) {
    const warningInfoIndex = collectorUnitDetailData.threshold?.findIndex(
      (item) => item.level === 2
    );
    if (warningInfoIndex !== -1) {
      warningInfo.flag = true;
      warningInfo.minThreshold =
        collectorUnitDetailData.threshold[warningInfoIndex].minThreshold;
      warningInfo.maxThreshold =
        collectorUnitDetailData.threshold[warningInfoIndex].maxThreshold;
    }
  }

  // 处理 预警阈值
  const earlyWarningInfo = { flag: false };
  if (collectorUnitDetailData.threshold) {
    const earlyWarningInfoIndex = collectorUnitDetailData.threshold?.findIndex(
      (item) => item.level === 4
    );
    if (earlyWarningInfoIndex !== -1) {
      earlyWarningInfo.flag = true;
      earlyWarningInfo.minThreshold =
        collectorUnitDetailData.threshold[earlyWarningInfoIndex].minThreshold;
      earlyWarningInfo.maxThreshold =
        collectorUnitDetailData.threshold[earlyWarningInfoIndex].maxThreshold;
    }
  }

  // 设置 区域线颜色值 (右上角部分)
  const pieces = [];
  const visualMap = {
    top: 50,
    right: 10,
    pieces,
    outOfRange: {
      color: '#999'
    }
  };
  if (warningInfo.flag && earlyWarningInfo.flag) {
    pieces.push({
      gt: 0.0,
      lte: warningInfo.minThreshold,
      label: `0.00 ${metricsData.unit} - ${Number(
        warningInfo.minThreshold
      ).toFixed(toFixedNum)} ${metricsData.unit}`, // 单位
      color: '#d84242' // 红色
    });
    pieces.push({
      gt: warningInfo.minThreshold,
      lte: earlyWarningInfo.minThreshold,
      label: `${Number(warningInfo.minThreshold).toFixed(toFixedNum)} ${
        metricsData.unit
      } - ${Number(earlyWarningInfo.minThreshold).toFixed(toFixedNum)} ${
        metricsData.unit
      }`, // 单位
      color: '#f59a23' // 黄色
    });
    pieces.push({
      gt: earlyWarningInfo.minThreshold,
      lte: earlyWarningInfo.maxThreshold,
      label: `${Number(earlyWarningInfo.minThreshold).toFixed(toFixedNum)} ${
        metricsData.unit
      } - ${Number(earlyWarningInfo.maxThreshold).toFixed(toFixedNum)} ${
        metricsData.unit
      }`, // 单位
      color: '#93CE07' // 绿色
    });
    pieces.push({
      gt: earlyWarningInfo.maxThreshold,
      lte: warningInfo.maxThreshold,
      label: `${Number(earlyWarningInfo.maxThreshold).toFixed(toFixedNum)} ${
        metricsData.unit
      } - ${Number(warningInfo.maxThreshold).toFixed(toFixedNum)} ${
        metricsData.unit
      }`, // 单位
      color: '#f59a23' // 黄色
    });
    pieces.push({
      gt: warningInfo.maxThreshold,
      label: `> ${Number(warningInfo.maxThreshold).toFixed(toFixedNum)} ${
        metricsData.unit
      }`, // 单位
      color: '#d84242' // 红色
    });
  } else if (!warningInfo.flag && earlyWarningInfo.flag) {
    // 只有预警
    pieces.push({
      gt: 0.0,
      lte: earlyWarningInfo.minThreshold,
      label: `0.00 ${metricsData.unit} - ${Number(
        earlyWarningInfo.minThreshold
      ).toFixed(toFixedNum)} ${metricsData.unit}`, // 单位
      color: '#f59a23' // 黄色
    });
    pieces.push({
      gt: earlyWarningInfo.minThreshold,
      lte: earlyWarningInfo.maxThreshold,
      label: `${Number(earlyWarningInfo.minThreshold).toFixed(toFixedNum)} ${
        metricsData.unit
      } - ${Number(earlyWarningInfo.maxThreshold).toFixed(toFixedNum)} ${
        metricsData.unit
      }`, // 单位
      color: '#93CE07' // 绿色
    });
    pieces.push({
      gt: earlyWarningInfo.maxThreshold,
      label: `> ${Number(earlyWarningInfo.maxThreshold).toFixed(toFixedNum)} ${
        metricsData.unit
      }`, // 单位
      color: '#f59a23' // 黄色
    });
  } else if (warningInfo.flag && !earlyWarningInfo.flag) {
    // 只有告警
    pieces.push({
      gt: 0.0,
      lte: warningInfo.minThreshold,
      label: `0.00 ${metricsData.unit} - ${Number(
        warningInfo.minThreshold
      ).toFixed(toFixedNum)} ${metricsData.unit}`, // 单位
      color: '#d84242' // 红色
    });
    pieces.push({
      gt: warningInfo.minThreshold,
      lte: warningInfo.maxThreshold,
      label: `${Number(warningInfo.minThreshold).toFixed(toFixedNum)} ${
        metricsData.unit
      } - ${Number(warningInfo.maxThreshold).toFixed(toFixedNum)} ${
        metricsData.unit
      }`, // 单位
      color: '#93CE07' // 绿色
    });
    pieces.push({
      gt: warningInfo.maxThreshold,
      label: `> ${Number(warningInfo.maxThreshold).toFixed(toFixedNum)} ${
        metricsData.unit
      }`, // 单位
      color: '#d84242' // 红色
    });
  } else {
    // visualMapPieces.push({
    //   gt: 0.0,
    //   label: `> 0.00 ${metricsData.unit}`, // 单位
    //   color: '#93CE07' // 绿色
    // });
  }

  // series.markLine.data
  const markLineData = [];
  const farmaterFunc = function (value, index) {
    return Number(value.value).toFixed(toFixedNum) + metricsData.unit; // 单位
  };
  if (warningInfo.flag) {
    markLineData.push({
      yAxis: warningInfo.minThreshold,
      lineStyle: {
        color: '#d84242' // 红色
      },
      label: {
        color: '#d84242', // 红色
        formatter: farmaterFunc
      }
    });
    markLineData.push({
      yAxis: warningInfo.maxThreshold,
      lineStyle: {
        color: '#d84242' // 红色
      },
      label: {
        color: '#d84242', // 红色
        formatter: farmaterFunc
      }
    });
  }
  if (earlyWarningInfo.flag) {
    markLineData.push({
      yAxis: earlyWarningInfo.minThreshold,
      lineStyle: {
        color: '#f59a23' // 黄色
      },
      label: {
        color: '#f59a23', // 黄色
        formatter: farmaterFunc
      }
    });
    markLineData.push({
      yAxis: earlyWarningInfo.maxThreshold,
      lineStyle: {
        color: '#f59a23' // 黄色
      },
      label: {
        color: '#f59a23', // 黄色
        formatter: farmaterFunc
      }
    });
  }
  // 设置 visualMap 和 series.markLine.data
  if (!(!warningInfo.flag && !earlyWarningInfo.flag)) {
    option.visualMap = visualMap;
    option.series.markLine.data = markLineData;
  }
  return option;
}

// 处理重置图片事件
function handleResizeEcharts() {
  myChart.resize();
}

// Echarts 销毁操作
onBeforeUnmount(() => {
  window.removeEventListener('resize', handleResizeEcharts);
  myChart && myChart.dispose();
  myChart = null;
  console.log('销毁Echarts图表');
});

// #region 分页逻辑部分
const totalCollectorUnitNum = ref(1); // 总数据条数
const PAGESIZE = 5; // 一页展示的数据条数

const pageInputNum = ref(1); // 输入框页码
const maxPageNum = ref(1); // 输入框中最大页码

const waringMsgListData = reactive([]);

const searchData = reactive({
  pageNum: 1,
  pageSize: PAGESIZE
});

// 页码跳转
function handleJumpPage() {
  searchData.pageNum = pageInputNum.value;
  getRecordListApi();
}
// #endregion

function handleAreadyRead(id) {
  const data = {
    id,
    status: true
  };
  putWaringMsgRead(data).then((res) => {
    const { code } = res;
    if (code === 200) {
      getRecordListApi();
    }
  });
}

// {
//         "id": 294,
//         "gmtCreate": "2022-12-05 23:53:01",
//         "level": 2,
//         "collectorUnitPath": "蓝亚办公司一号电箱/电源监测采集器/继电器",
//         "collectorUnitCode": "5D8FF373338564643175155_001",
//         "content": {
//           "waringLevel": "报警: 采集指标信息异常,请处理！",
//           "info": "尊敬的客户您好，您管理的电源监测采集器出现报警问题继电器电路异常，请查看通知及时处理"
//         },
//         "status": true
//       },

function getRecordListApi() {
  const params = {
    code: collectorUnitDetailData.code,
    pageNum: searchData.pageNum,
    pageSize: PAGESIZE
  };
  getRecordList(params).then((res) => {
    const { code, data, msg } = res;
    if (code === 200) {
      const { list, total, pages } = data;
      const length = waringMsgListData.length;
      waringMsgListData.splice(0, length, ...list);
      maxPageNum.value = pages;
      totalCollectorUnitNum.value = total;
    } else {
      ElMessage.error(msg);
    }
  });
}

// 设备类型字符串
const equipmentType = ref('');
// 显示采集单元对应的 采集器类型图标
const imgUrl = computed(() => {
  switch (equipmentType.value) {
    case '水质监测':
      return waterTypeImg;
    case '电源监测':
      return powerTypeImg;
    case '土壤监测':
      return soilTypeImg;
    case '设备监测':
      return equipmentTypeImg;
    case '天气监测':
      return soilTypeImg;
    default:
      return normalTypeImg;
  }
});
// 获取设备类型
// function getEquipmentListTypeApi(unitId) {
//   // 根据 采集单元 Id 查询采集器ID
//   getCollectorDetail(unitId)
//     .then((res) => {
//       const { code, msg, data } = res;
//       if (code === 200) {
//         const { equipmentId } = data;
//         return getEquipmentDetail(equipmentId);
//       } else {
//         console.log('getCollectorDetail 查询采集器详情出错了');
//         ElMessage.error('出错啦!', msg);
//       }
//     })
//     .then((res) => {
//       const { code, msg, data } = res;
//       if (code === 200) {
//         const { type } = data;
//         console.log('千辛万苦查得到类型是:', type);
//         equipmentType.value = type;
//       } else {
//         console.log('getCollectorDetail 查询设备详情出错了');
//         ElMessage.error('出错啦!', msg);
//       }
//     });
// }

function init() {
  equipmentType.value = localStorage.getItem('equipmentType');
  getCollectorUnitDetailApi(unitId).then(() => {
    const chartDom = chartRef.value;
    // console.log(chartDom);
    // 这里处理 chartDom 为null的情况 BUGGER TOFIX
    if (chartDom) {
      myChart = echarts.init(chartDom);
      const option = getEchartsOption();

      option && myChart.setOption(option);
      window.addEventListener('resize', handleResizeEcharts);
    }
    // myChart = echarts.init(chartDom);
    // const option = getEchartsOption();

    // option && myChart.setOption(option);
    // window.addEventListener('resize', handleResizeEcharts);
  });
}

onMounted(() => {
  initFlag && init();
});
</script>

<style lang="scss" scoped>
.collectorUnitDetail_wrap {
  .collectorUnitDetail_box {
    background-color: #fff;
    margin: 1rem 14px 8px 14px;
    display: flex;
    justify-content: space-between;

    .box_card {
      .box_card_title_wrap {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        .box_card_title {
          font-size: 18px;
        }
      }

      // 采集器单元详情
      &.left_box_card {
        width: 67%;
        min-width: 710px;

        .detail_main_wrap {
          width: 100%;
          display: flex;
          .detail_left_main_wrap {
            width: 25%;
            display: flex;
            align-items: center;
            img {
              width: 150px;
              height: 150px;
            }
          }
          .detail_right_main_wrap {
            width: 75%;
            display: flex;
            justify-content: space-between;
            .left_text_info_box {
              width: 45%;
              display: flex;
              flex-direction: column;
              margin-top: 10px;
              .detail_info_row {
                // 省略三连
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;

                font-size: 14px;
                width: 100%;
                height: 30px;
                line-height: 30px;
                // color: rgba(0, 0, 0, 0.65);
                color: black;
                span {
                  color: #9a9a9a;
                }
              }
            }
            .right_text_info_box {
              width: 45%;
              display: flex;
              flex-direction: column;
              margin-top: 10px;
              .detail_info_row {
                // 省略三连
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;

                font-size: 14px;
                width: 100%;
                height: 30px;
                line-height: 30px;
                // color: rgba(0, 0, 0, 0.65);
                color: black;
                span {
                  color: #9a9a9a;
                }
              }
            }
          }
        }
      }

      // 天气部分
      &.right_box_card {
        width: 32%;

        .threshold_box_wrap {
          width: 100%;
          display: flex;
          flex-direction: column;
          .threshold_row {
            font-size: 14px;
            display: flex;
            width: 100%;
            height: 30px;
            flex: auto;
            justify-content: space-around;
            align-items: center;
            .text_color_orange {
              color: #f59a23;
            }
            .text_color_red {
              color: #d84242;
            }
            .threshold_text {
              width: 120px;
              text-align: center;
            }
            .threshold_delete_btn {
              width: 50px;
              text-align: center;
            }
          }
        }
      }
    }
  }
  .collectorUnitList_box {
    margin: 14px;
    background-color: #fff;
    .collectorUnitList_wrap {
      padding: 14px;
      display: flex;
      justify-content: space-between;
      height: 565px;

      .collectorUnit_echarts_wrap {
        flex: auto;
        width: 73%;
        height: 550px;
        .echarts_title {
          margin-bottom: 5px;
        }
      }
      .warningMsgList_wrap {
        flex: auto;
        width: 35%;
        padding-left: 1%;
        border-left: 2px solid #e8e8e8;
        height: 550px;
        flex-direction: column;
        .warningMsgList_title {
          height: 30px;
          margin-bottom: 5px;
        }
        .warningMsgList_main {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 500px;

          :deep(.scope) {
            display: flex;
            flex-direction: column;
            .scope_row {
              display: flex;
              justify-content: space-between;
              align-items: center;
              .collectorUnit_red_name {
                color: #d84242;
                font-weight: 600;
              }
              .collectorUnit_yellow_name {
                color: #f59a23;
                font-weight: 600;
              }
            }
            .collectorUnit_name {
              color: rgba(0, 0, 0, 0.95);
              font-size: 14px;
            }
          }

          :deep(.btns) {
            display: flex;

            .btn {
              margin: 0 8px;
              color: #1890ff;
              cursor: pointer;
            }

            .line {
              margin-top: 2px;
              width: 1px;
              height: 16px;
              border-left: 1px solid #dcdfe6;
            }
          }

          .pagination_row {
            margin-top: 10px;
            height: 40px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .pagination_row_left {
              font-size: 13px;
              color: #606266;
            }

            .pagination_row_right {
              display: flex;
              font-size: 13px;
              align-items: center;

              .pagination_item {
                height: 30px;
                :deep(button) {
                  height: 30px;
                }
                :deep(ul li) {
                  height: 30px;
                }
              }
              .pagination_row_input_wrap {
                margin-left: 10px;
                .pagination_row_input_box {
                  margin-left: 10px;
                  margin-right: 10px;
                }
                // 这部分是可以自然设置number input部分样式(保留)
                // #region
                // .pageInput {
                //   &:deep(.el-input__inner) {
                //     height: 30px;
                //     line-height: 30px;
                //     box-sizing: border-box;
                //   }
                //   &:deep(.el-input-number__decrease) {
                //     height: 30px;
                //     line-height: 30px;
                //     box-sizing: border-box;
                //   }
                //   &:deep(.el-input-number__increase) {
                //     height: 30px;
                //     line-height: 30px;
                //     box-sizing: border-box;
                //   }
                // }
                // #endregion
              }

              .pagination_row_btn {
                margin-left: 10px;
              }
            }
          }
        }
      }
    }
  }

  .edit_collectorUnit_dialog_box {
    .dialog_main {
      .dialog_row {
        display: flex;
        align-items: center;
        height: 30px;
        .dialog_row_text {
          margin-left: 15px;
        }
      }
      .dialog_row_box {
        margin-top: 10px;
        .dialog_box_row {
          display: flex;
          align-items: center;
          height: 30px;
          .dialog_row_text {
            margin-left: 15px;
          }
        }

        .warning_row {
          display: flex;
          align-items: center;
          height: 30px;
          .warning_row_text {
            margin-right: 15px;
          }
        }
      }
    }
  }
}
</style>
